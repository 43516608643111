import styled from "styled-components";

const TitleRegis = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #EFF1FF;
    margin: 20px;
`;

const ConditionLabel = styled.div`
    font-family: 'Kanit', sans-serif;
    color: #EFF1FF;
    display: flex;
    align-items: canter;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
`;

const CreateAs = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem
    font-weight: 400;
    color: #FFFFFF;
`;

export const LabelInputText = styled(CreateAs)`
    position: relative;
    bottom: 1px;
    right: 100px;
    border-radius: 5px;
    font-weight: 400;
    color: #EFF1FF;
    margin: 20px 110px 0 105px;
    text-align: left;
`;

export const RequirementText = styled.p`
    font-family: 'Kanit', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    color: red;
    text-align: left;
    position: relative;
    bottom: 1px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
`;

const MemType = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: ${(props) => props.color};
`;

const CardName = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000000;
    text-align: center;
    line-height: 20px;
    margin-top: 10px;
`;

const MemInfo = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(0, 0, 0, 0.9);
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
`;

const MemID = styled(MemInfo)`
    font-weight: 600;
`;

const EditText = styled(MemInfo)`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #6072F3;
    text-decoration: underline;
    margin-left: 180px;
`;

export const BackText = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem
    font-weight: 400;
    color: #CDD3FF;
`;

export const ErrorHeader = styled.div`
    font-family: 'Kanit', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: #FF594B;
    line-height: 35px;
`;

export const ErrorDesc = styled(ErrorHeader)`
    font-size: 1.2rem;
    font-weight: 500;
    color: #FFEEED;
    line-height: 25px;
    margin-bottom: 35px;
`;

export const CardScanTitle = styled.p`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 5px;
`;

export const CardScanLabel = styled.p`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const CardScanInfo = styled.p`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #AFB9FF;
    width: 40vw;
    padding-left: 5px;
`;

export const InsertTitle = styled(TitleRegis)`
    margin: 30px;
`;


export const InsertText = styled.label`
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #CDD3FF;
    margin: 10px;
`;

export { TitleRegis, ConditionLabel, CreateAs, MemType, CardName, MemInfo, MemID, EditText };