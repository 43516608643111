import styled from "styled-components";

const TransBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    width: 100vw;
    min-height: 76vh;
    background-color: rgb(255, 255, 255, 0.07);
    border: none;
    border-radius: 15px 15px 0px 0px;
    padding-top: 30px;
    padding-bottom: 30px;
`;

export default TransBox;