import liff from '@line/liff';
import { useState } from 'react';
import { checkIn, searchUserByRunningNumber, UserAsResponse } from '../controller/UsersAPI';
import CardScan from './CardScan';
import Center from '../components/Center';
import logo_staff from '../public/Logo_staff.svg';
import { PrimaryButton, BlackButton, DisableButton } from '../components/Button';
import { MyLink } from '../components/MyLink';

interface MainProps {
    isRegis: boolean,
    staff_id: string
}

function Main(props: MainProps) {
    const { isRegis, staff_id } = props;
    const [retrivedUser, setRetrivedUser] = useState<UserAsResponse>();
    const scanQRCode = () => {
        liff.scanCodeV2().then(({ value }) => {
            if (value) {
                console.log(value);
                searchUserByRunningNumber(Number(value))
                    .then(({ error, message, user }) => {
                        if (!error) {
                            setRetrivedUser(user);
                            if (user.user_id !== retrivedUser?.user_id) {
                                checkIn(user.user_id, staff_id).then(({ error, message }) => {
                                    if (!error) {
                                        console.log("checkin succ");
                                        alert("เช็คอินสำเร็จ!");
                                    }
                                    else {
                                        console.error("checkin fail", message);
                                        alert("ไม่สามารถเช็คอินได้" + JSON.stringify(message));
                                    }
                                }).catch(e => {
                                    console.error("internal", e);
                                    alert("internal error: " + e);
                                });
                            }
                            else {
                                console.log("self checkin wtf");
                                alert("ไม่สามรถเช็คอินให้ตัวเองได้!");
                            }
                        }
                        else {
                            console.log("can't fetch user by running number:", message);
                            alert(JSON.stringify(message));
                        }
                    })
            }
            else {
                console.log("scan fail");
                alert("scan fail");
            }
        });
    }
    return (
        <Center>
            <img src={logo_staff} style={{ width: '70vw', marginTop: '80px' }} alt='88sandbox_logo_staff'></img>
            <div style={{ marginTop: '70px', height: '68vh' }}>
                {retrivedUser && <CardScan user={retrivedUser} />}
                {!isRegis ?
                    <>
                        <MyLink to="/regis">
                            <BlackButton>
                                Create my card
                            </BlackButton>
                        </MyLink>
                    </>
                    : <>
                        <PrimaryButton style={{ height: '79px' }} onClick={scanQRCode}>
                            Scan QR
                        </PrimaryButton><br />
                        <MyLink to="/card">
                            <BlackButton>
                                My card
                            </BlackButton>
                        </MyLink>
                    </>
                }
            </div>

        </Center>
    );
}

export default Main;
