import React from 'react'
import CardScanBG from '../components/CardScanBG'
import Center from '../components/Center'
import { CardScanTitle, CardScanLabel, CardScanInfo } from '../components/Text';
import { UserAsResponse, USERTYPE_TO_INT } from '../controller/UsersAPI';
interface CardScanProps {
    user: UserAsResponse,
}
export default function CardScan(props: CardScanProps) {
    const { user } = props;
    const { user_type, firstname, lastname } = user;
    let role = "";
    switch(user_type){
        case USERTYPE_TO_INT.StartupTeam:
            role = "Startup Team";
            break;
        case USERTYPE_TO_INT.Mentor:
            role = "Mentor";
            break;
        case USERTYPE_TO_INT.VC:
            role = "Venture Capital";
            break;
        case USERTYPE_TO_INT.TUStudent:
            role = "TU Student";
            break;
        case USERTYPE_TO_INT.Visitor:
            role = "Visitor";
            break;
        case USERTYPE_TO_INT.RoadShow:
            role = "Roadshow Participant";
            break;
        case USERTYPE_TO_INT.Staff:
            role = "Staff";
        break;        
    } 
    return (
        <Center style = {{marginTop: '10px'}}>
            <CardScanBG>
                <CardScanTitle>Card Info</CardScanTitle>
                <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div style={{textAlign: 'right', paddingRight: '10px'}}>
                        <CardScanLabel>Firstname:</CardScanLabel>
                        <CardScanLabel>Lastname:</CardScanLabel>
                        <CardScanLabel>Role:</CardScanLabel>
                    </div>
                    <div style={{textAlign: 'left', paddingLeft: '10px'}}>
                        <CardScanInfo>{firstname}</CardScanInfo>
                        <CardScanInfo>{lastname}</CardScanInfo>
                        <CardScanInfo>{role}</CardScanInfo>
                    </div>
                </div>
            </CardScanBG>
        </Center>
    )
}
