import { useState } from 'react';
import Center from '../components/Center';
import { PrimaryButton } from '../components/Button';
import TransBox from '../components/TransBox';
import { TextBox, CheckBox} from '../components/InputBox';
import { ConditionLabel, LabelInputText, TitleRegis, RequirementText } from '../components/Text';
import { MyLink2 } from '../components/MyLink';
import { BackText } from '../components/Text';
import RegisTop from './RegisTop';
import { useNavigate } from "react-router-dom";
import { 
    staffRegister,
    UserAsResponse, 
    InsertUserResponse, 
    editUser } from '../controller/UsersAPI';
import back from '../public/feather chevron left 1.png';

interface RegisFormProps {
    profilePic: string, 
    user: UserAsResponse, 
    setUser: (user: UserAsResponse) => void, 
    condition: boolean,
    setCondition: (v:boolean) => void,
    isRegis: boolean,
    setIsRegis: (v:boolean) => void
}

function RegisForm(props: RegisFormProps) { 
    const {user, setUser, condition, setCondition, isRegis, setIsRegis} = props;
    const {
        firstname, 
        lastname, 
        birthday, 
        email, 
        nickname, 
        phone_number, 
        user_id, 
        faculty_id, 
        organization, 
        position, 
        student_id, 
        team_name,
    } = user;
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [nicknameErr, setNicknameErr] = useState(false);
    const [birthdayErr, setBirthdayErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const validPhoneNum = new RegExp('^[0-9]{10}$');
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    const navigate = useNavigate();

    const postData = async () => {
        console.log(user_id);
        console.log(firstname);
        console.log(lastname);
        console.log(nickname);
        console.log(email);
        console.log(phone_number);
        
        let regisPromise:Promise<InsertUserResponse> = staffRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday);        
        if(regisPromise){
            regisPromise
                .then((v) => {
                    if(!v.error){ // create success goes here
                        console.log('submission success');
                        setUser(v.user);
                        setIsRegis(true);
                        navigate("/card");
                    }
                    else{ // create fail goes here
                        alert("probably already register: " + v.message);
                        console.error(v.message);
                    }
                })
                .catch((e) => { // promise problem goes here
                    console.error("internal problem?", e);
                    alert("internal problem?" + String(e));
                });
        }
        else{
            alert("wrong role?")
        }
    }

    const editData = async () => {
        const editRes = editUser(user_id, firstname, lastname, nickname, email, phone_number, birthday, team_name, organization, position, faculty_id, student_id);
        editRes.then(v => {
            if(!v.error){
                console.log('submission success');
                navigate("/card");
            }
            else{
                alert("probably already register: " + v.message);
                console.error(v.message);
            }
        })
        .catch(e => {
            console.error("internal problem?", e);
            alert("internal problem?" + String(e));
        })
    }

    const formValidate = () => {
        if(!user.firstname) {
            setFirstNameErr(true); 
            return false;
        }else setFirstNameErr(false)
        if(!user.lastname) {
            setLastNameErr(true); 
            return false;
        }else setLastNameErr(false)
        if(!user.nickname) {
            setNicknameErr(true); 
            return false;
        }else setNicknameErr(false)
        if(!user.birthday) {
            setBirthdayErr(true); 
            return false;
        }else setBirthdayErr(false)
        if(!user.email || validEmail.test(user.email)===false) {
            setEmailErr(true); 
            return false;
        }else setEmailErr(false)
        if(!user.phone_number || validPhoneNum.test(user.phone_number)===false) {
            setPhoneErr(true); 
            return false;
        }else setPhoneErr(false)
        return true;
    }

    const onSubmit = (e: any) => {
        let formvalid = formValidate();
        e.preventDefault(); //refresh prevented
        
        if(formvalid){
            if(isRegis){
                editData();
            }
            else {
                postData();
            }
        }
    }

    return ( 
        <>
            <div onClick={() => navigate(-1)} style ={{position: 'absolute', display: 'flex', flexDirection:'row', left: '20px', textDecoration: 'none'}}>
                <img alt='<' src={back} style={{width:'25px', height: '25px'}}/>
                <BackText>Back</BackText>
            </div>
            <Center>
                <RegisTop />
                <TransBox>
                    <TitleRegis>Personal Information</TitleRegis>
                    <form onSubmit = { onSubmit } style={{paddingLeft: '30px', paddingRight: '30px'}}>
                        <LabelInputText>First name</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อจริงเป็นภาษาอังกฤษ'
                            value = { firstname }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.firstname = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {firstNameErr && <RequirementText>First name is required.</RequirementText>}
                        <LabelInputText>Last name</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุนามสกุลเป็นภาษาอังกฤษ'
                            value = { lastname }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.lastname = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {lastNameErr && <RequirementText>Last name is required.</RequirementText>}
                        <LabelInputText>Nickname</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อเล่นเป็นภาษาอังกฤษ'
                            value = { nickname }
                            onChange = { (e) => {
                                let tmpUser = user;
                                tmpUser.nickname = e.target.value;
                                setUser(tmpUser);
                            }
                        }
                        />
                        {nicknameErr && <RequirementText>Nickname is required.</RequirementText>}
                        <LabelInputText>Birthdate</LabelInputText>
                        <TextBox type = 'date' placeholder = 'กรุณาระบุวันเกิดของคุณ'
                            value = { birthday.toISOString().split("T")[0] }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.birthday = new Date(e.target.value);
                                    setUser(tmpUser);
                                }
                            } 
                        />
                        {birthdayErr && <RequirementText>Birthdate is required.</RequirementText>}
                        <LabelInputText>Email</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุอีเมลของคุณ'
                            value = { email }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.email = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {emailErr && <RequirementText>Email is required.</RequirementText>}
                        <LabelInputText>Phone No.</LabelInputText>
                        <TextBox type = 'number' placeholder = '0882345678'
                            value = { phone_number }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.phone_number = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {phoneErr && <RequirementText>Phone No. is required.</RequirementText>}
                        <ConditionLabel style={{marginTop: '20px'}}>
                            <CheckBox type = 'checkbox' name = 'conditions' onChange = { (e) => setCondition(!condition) } required/>
                            I agree to the&nbsp; 
                            <MyLink2 to = '/privacy-policy'>Privacy Policy.</MyLink2>
                        </ConditionLabel>
                        <PrimaryButton type = 'submit'>
                            {isRegis?
                                <>Edit my card</>
                                :<>Create my card</>
                            }
                        </PrimaryButton>
                    </form>
                </TransBox>
            </Center>
        </>
    )                      
}
export default RegisForm;