import styled from "styled-components";

const TextBox = styled.input`
    display: inline;
    margin-bottom: 2px;
    width: 72vw;
    height: 54px;
    background: transparent;
    border: 1px solid #EFF1FF;
    border-radius: 5px;
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    padding:  0 1rem 0 1rem;
    color: #CDD3FF;
    :focus {
        color: #CDD3FF;
        border-radius: 0;
    }
    ::placeholder {
        color: #888888;
    }
`;

const CheckBox = styled.input`
    margin-right: 10px;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: #CDD3FF;
`;

export const SearchBox = styled(TextBox)`
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

export const RadioButt = styled.input`
    -webkit-transform: scale(1.5);
`;

export { TextBox, CheckBox };