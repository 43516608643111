import Center from '../components/Center';
import { CreateAs, MemType } from '../components/Text';

function RegisTop(){
    return ( 
        <Center>
            <div style = {{ margin:'20px' }}>
                <CreateAs>Create card as</CreateAs>
                <MemType color = '#EB585C'>Staff</MemType>
            </div>
        </Center>
    ); 
} 
export default RegisTop;