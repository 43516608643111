import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import liff from '@line/liff';
import { Component, ReactNode } from 'react';
import { searchUserByUserID, UserAsResponse } from './controller/UsersAPI';
import Main from './view/Main';
import RegisForm from './view/RegisForm';
import Card from './view/Card';
import PrivacyPolicy from './view/PrivacyPolicy';
import { USERTYPE_TO_INT } from './controller/UsersAPI';

const defaultProfilePic = "/logo192.png";

class App extends Component<{}, {profilePic: string, isRegis: boolean, condition: boolean, user: UserAsResponse}> {
    setUser = (user: UserAsResponse) => this.setState({user: user});
    setProfilePic = (profilePic: string) => this.setState({profilePic: profilePic});
    setIsRegis = (isRegis: boolean) => this.setState({isRegis: isRegis});
    setCondition = (condition: boolean) => this.setState({condition: condition});
    constructor(props:{}){
        super(props);
        this.state = {
            profilePic: defaultProfilePic,
            isRegis: false,
            condition: false,
            user: {
                user_id: "",
                firstname: "",
                lastname: "",
                birthday: new Date(),
                email: "",
                nickname: "",
                phone_number: "",
                team_name: "",
                created_at: new Date(),
                running_number: -1,
                faculty_id: -1,
                user_type: USERTYPE_TO_INT["Staff"]   
            }
        };
    }

    componentDidMount(){
        console.log(process.env.REACT_APP_LIFF_ID)
        console.log(process.env.REACT_APP_API_URL)
        liff.init({liffId: process.env.REACT_APP_LIFF_ID})
            .then(() => {
                if(!liff.isLoggedIn()){
                    liff.login();
                }
                liff.getProfile().then((profile) => {
                    let tmpUser = this.state.user;
                    tmpUser.user_id = profile.userId;
                    this.setUser(tmpUser);
                    
                    this.setProfilePic(profile.pictureUrl ? profile.pictureUrl : defaultProfilePic);
                    searchUserByUserID(profile.userId).then(({error, message, user}) => { // check if already registered
                        if(error) alert("internal error: " + message);
                        else if(!error && user !== null) {
                            this.setIsRegis(true);
                            this.setUser(user);
                        };
                    });
                });
            })
            .catch((e) => {
                console.error("init error", e);
            });
    }

    render(): ReactNode {
        return (
            <BrowserRouter> 
                <Routes>
                    <Route path="/" element={
                        <Main 
                            staff_id={this.state.user.user_id}
                            isRegis={this.state.isRegis}
                        />
                    } />
                    <Route path="/regis" element={
                        <RegisForm 
                            user={this.state.user} 
                            setUser={this.setUser} 
                            condition={this.state.condition} 
                            setCondition={this.setCondition} 
                            isRegis={this.state.isRegis} 
                            setIsRegis={this.setIsRegis}
                            profilePic="/logo192.png"
                        />
                    }/>
                    <Route path="/card" element={
                        <Card 
                            user={this.state.user} 
                            QRcontent={String(this.state.user.running_number)} 
                            setCondition={this.setCondition}
                            profilePic={this.state.profilePic}
                        />
                    }/>
                    <Route path="/privacy-policy" element = {<PrivacyPolicy/>} ></Route>
                </Routes>
            </BrowserRouter> 
        );
    }
}

export default App;
