import CardBG from '../components/CardBG';
import { CardName, EditText, MemID, MemType} from '../components/Text';
import Center from '../components/Center';
import { MyLink } from '../components/MyLink';
import { Border } from '../components/Border';
import { BlackButton } from '../components/Button';
import { QRCodeSVG } from 'qrcode.react';
import { UserAsResponse } from '../controller/UsersAPI';
import { Link } from "react-router-dom";
import thumbup from '../public/UnicornCEOThumbsup.png';
import mascot from '../public/H_01.png';

interface CardProps {
    user: UserAsResponse,
    profilePic?: string,
    QRcontent?: string,
    setCondition?: (v:boolean) => void
}
function Card(props: CardProps){
    const { setCondition, user, profilePic, QRcontent } = props;
    const { user_type, firstname, lastname } = user;

    return ( 
        <Center>
            <CardBG user_type = { user_type }>
                {
                    setCondition && (
                        <Link to="/regis">
                            <EditText onClick = { () => setCondition(false) }>edit</EditText>
                        </Link>
                    )
                }
                <Center style = {{ margin: '0' }}>
                    {
                        profilePic 
                        ? <img src = { profilePic } alt = 'profile pic' style = {{ height:'82px', width: '82px', borderRadius: '50%', display: 'flex', alignContent: 'center'}}/>
                        : <img src = { mascot } alt = 'profile pic' style = {{ height:'82px', width: '82px', borderRadius: '50%', display: 'flex', alignContent: 'center'}}/>
                    }
                </Center>
                <CardName>
                    { firstname }<br/>
                    { lastname }
                </CardName>
                <Center style={{ margin: '0' }}>
                    <Border/>
                <MemType style = {{color: '#A70100', marginBottom: '10px'}}>Staff</MemType>
                <div style = {{width: '150px', height: '150px', paddingTop: '15px', backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                    {
                        QRcontent && (
                            <>
                                <QRCodeSVG value={QRcontent} style={{height: '110px'}} />
                                <MemID>#Unicorn{QRcontent}</MemID>
                            </>
                        )
                    }
                </div>
                </Center>
            </CardBG>
            <MyLink to='/'><BlackButton>Back to home</BlackButton></MyLink>
            <div>
                <img 
                    src={thumbup} 
                    alt='img_thumbsup'
                    style={{height: '125px', width: '125px', position: 'relative', right: '107px', bottom: '240px'}}
                />
            </div>
        </Center>
    );
} 
export default Card;